<template>
  <el-form
    ref="formRef"
    v-loading="loading"
    :model="form"
    :rules="rules"
    label-width="100px"
    class="mt-6"
    :label-position="isMobile ? 'top' : 'left'"
  >
    <div class="flex space-x-6 md:flex-col md:space-x-0">
      <div class="w-1/2 md:w-full">
        <el-form-item label="聯絡主題 " prop="typeId">
          <el-select v-model="form.typeId" placeholder="聯絡主題">
            <el-option
              v-for="opt in typeOpts"
              :key="opt.id"
              :label="opt.name"
              :value="opt.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="問題ID" prop="qId">
          <el-input v-model="form.qId" placeholder="" />
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="請輸入您的姓名" />
        </el-form-item>
        <el-form-item label="電話" prop="phone">
          <el-input v-model="form.phone" placeholder="請輸入您的電話" />
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" placeholder="" />
        </el-form-item>
      </div>
      <div class="w-1/2 md:w-full">
        <el-form-item label="訊息內容" prop="content">
          <el-input
            v-model="form.content"
            clearable
            type="textarea"
            rows="8"
            show-word-limit
            :maxlength="500"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <div class="flex justify-between">
      <div></div>
      <el-button type="primary" size="large" @click="onSubmit">送出</el-button>
    </div>
  </el-form>
</template>
<script setup>
import { inject, toRefs, defineEmits, defineProps } from 'vue';
import { ElFormItem, ElForm, ElInput, ElSelect, ElOption } from 'element-plus';
import useContact from './useContact.js';

const emit = defineEmits(['submitted']);
const props = defineProps({
  qId: { type: String, default: '' },
});
const { qId } = toRefs(props);
const { form, formRef, typeOpts, rules, submit, loading } = useContact(qId);
const onSubmit = () => {
  submit().then((res) => {
    if (res.code === 1) {
      emit('submitted');
    }
  });
};

const isMobile = inject('isMobile');
</script>
