import Axios from './http';
const prefix = '/consumer/v1';
export default class Contact {
  static async types(params) {
    return Axios(prefix + '/cs/csTypes', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async send(params) {
    return Axios(prefix + '/cs/report', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async faq(params) {
    return Axios(prefix + '/cs/faq', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
