import { reactive, watch, ref } from 'vue';
import Contact from '@/api/Contact';
import { ElMessage } from 'element-plus';
import _ from 'lodash';
export default () => {
  const rows = reactive([]);
  const dataType = reactive([]);
  const form = reactive({
    dataType: 'all',
  });
  const loading = ref(false);
  const fetcher = () => {
    loading.value = true;
    Contact.faq({ dataType: form.dataType }).then((res) => {
      if (res.code === 1) {
        rows.length = 0;
        dataType.length = 0;
        rows.push(...res.data.rows);
        dataType.push(...res.data.dataType);
      } else {
        ElMessage({ message: '發生錯誤' });
      }

      loading.value = false;
    });
  };
  watch(
    () => _.cloneDeep(form),
    (val, old) => {
      if (_.isEqual(val, old)) return;

      fetcher();
    },
    { immediate: false, deep: true },
  );

  return { rows, fetcher, dataType, form, loading };
};
