<template>
  <div v-loading="loading">
    <div class="flex items-center">
      <slot name="header"> </slot>

      <data-type-filter
        v-model="form.dataType"
        class="sm:w-full w-auto"
        :opts="dataType"
      />
    </div>
    <el-collapse class="mt-4">
      <el-collapse-item v-for="row in rows" :key="row.id">
        <template #title>
          <div>
            <div class="">{{ row.title }}</div>
            <div class="text-gray">{{ row.typeName }}</div>
          </div>
        </template>
        <div class="whitespace-pre px-4" v-html="row.content"></div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script setup>
import { ElCollapse, ElCollapseItem } from 'element-plus';
import DataTypeFilter from '@/components/filter/DataType.vue';
import useFaq from './useFaq.js';

const { rows, fetcher, form, dataType, loading } = useFaq();
fetcher();
</script>

<style lang="sass" scoped>
.el-collapse
  --el-collapse-border-color: transparent
  @apply space-y-3


  .el-collapse-item
    @apply rounded-lg shadow bg-white border overflow-hidden duration-300
    &:hover
      @apply shadow-lg
    .el-collapse-item__header
      @apply border-0 text-lg text-primary py-6 duration-300 h-auto
      // &.is-active
      //    @apply text-black
      > div
        @apply flex justify-between pr-12 pl-4 w-full
        @screen md
          @apply flex-col-reverse
          > .text-gray
            @apply text-xs mb-1
    .el-collapse-item__content
      @apply  border-0 pl-4 pr-8 text-base pt-1
</style>
