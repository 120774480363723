<template>
  <div class="container my-12">
    <div class="bg-white rounded shadow p-12 md:px-4">
      <div class="text-xl">
        有想要問的問題嗎? 也可以先看看
        <a href="#faq" class="underline text-primary"> FAQ</a> 喔!
      </div>
      <hr class="my-2" />
      <ContactForm :q-id="$route.query.qId"></ContactForm>
    </div>

    <div id="faq" class="mt-6">
      <Faq-List>
        <template #header>
          <Title text="FAQ"></Title>
        </template>
      </Faq-List>
    </div>
  </div>
</template>

<script setup>
import Title from '@/components/Titles';
import FaqList from '@/modules/faq/List.vue';
import ContactForm from '@/modules/contact/Form.vue';

import { inject } from 'vue';

const isMobile = inject('isMobile');
</script>
