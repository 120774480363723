import { reactive, ref, inject } from 'vue';
import Contact from '@/api/Contact';
import { ElMessage } from 'element-plus';

import Regex from '@/utils/Regex';
import { useRoute } from 'vue-router';
export default (qId) => {
  const auth = inject('auth');
  const route = useRoute();
  const defaultForm = {
    typeId: '',
    name: '',
    phone: '',
    email: auth.user.accountType === 'native' ? auth.user.email : '',
    content: '',
    qId: qId,
  };
  const form = reactive({ ...defaultForm });
  const reset = () => {
    Object.assign(form, { ...defaultForm });
  };

  const rules = reactive({
    typeId: [
      {
        required: true,
        message: '未填寫內容',
        trigger: 'blur',
      },
    ],
    name: [{ max: 11, message: '長度需要不可以超過10個字', trigger: 'blur' }],
    phone: [
      {
        validator: (rule, value, callback) => {
          if (value && !Regex.phone.test(value)) {
            callback(new Error('格式錯誤'));
          } else {
            callback();
          }
        },
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: '未填寫內容',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: '格式錯誤',
        trigger: ['blur'],
      },
    ],
    content: [
      {
        required: true,
        message: '未填寫內容',
        trigger: 'blur',
      },
    ],
  });
  const typeOpts = reactive([]);
  const fetcherOpts = () => {
    Contact.types().then((res) => {
      if (res.code === 1 && res.data.rows?.length) {
        typeOpts.push(...res.data.rows);
        form.typeId = typeOpts[0].id;
      }
    });
  };
  fetcherOpts();

  const loading = ref(false);
  const submit = () => {
    return new Promise((resolve) => {
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          Contact.send({ ...form }).then((res) => {
            if (res.code === 1) {
              ElMessage({ type: 'success', message: '成功寄出!' });
              reset();
            } else {
              const errors = {
                '001': '問題ID錯誤',
              };
              ElMessage({
                type: 'error',
                message: errors[res.errorCode] || '資料錯誤',
              });
            }

            loading.value = false;
            return resolve(res);
          });
        } else {
          return resolve({ code: 0 });
        }
      });
    });
  };
  const formRef = ref();
  return { formRef, form, typeOpts, rules, submit, loading };
};
